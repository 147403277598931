import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { statuses as ServiceDeliveryPointStatus } from '../shared/model/service-delivery-point.model';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { Timezone } from '../shared/model/timezone.model';
import { Locale, LocaleBaseConfig } from '../shared/model/locale.model';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import { TranslateService } from '@ngx-translate/core';
import { ServiceDeliveryPointService } from '../shared/services/service-delivery-points.service';
import { TypeService } from '../shared/services/types.service';
import { CommoditiesService } from '../shared/services/commodities.service';
import { Subscription } from 'rxjs';
import { SiteService } from '../shared/services/sites.service';
import { tap } from 'rxjs/operators';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-service-delivery-point-form',
  templateUrl: './service-delivery-point-form.component.html',
  styleUrls: ['./service-delivery-point-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ServiceDeliveryPointFormComponent implements OnInit {
  readonly EDIT = 'edit';
  readonly CREATE = 'create';

  APPPREFIX = 'uaa';

  locales: Locale[];
  timezones: Timezone[];
  statuses = ServiceDeliveryPointStatus;

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [],
    locales: [],
    defaultLocale: null,
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  multidisplayLabels: any;
  multiDescriptionDisplayLabels: any;
  namePlaceholder: string = '';
  descriptionPlaceholder: string = '';
  nameLabel: string = '';
  descriptionLabel: string = '';
  userLocale = 'en_US';
  commodities = [];
  types = [];
  isFirstTime = true;
  sdp: any;

  @ViewChild('multiLocaleLabels') multiLocaleDisplayLabels: any;
  @ViewChild('multiDisplaylabels') multiDisplaylabels: any;
  @ViewChild('multiLocaleDescriptionLabels') multiLocaleDescriptionDisplayLabels: any;
  @ViewChild('multiDescriptionlabels') multiDescriptionlabels: any;
  @Input() serviceDeliveryPointForm: FormGroup;
  _serviceDeliveryPoint;
  selectedSite: string;
  loadSite = true;

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get displayLabels() {
    return this.serviceDeliveryPointForm.get('displayLabels');
  }
  get siteId() {
    return this.serviceDeliveryPointForm.get('siteId');
  }
  get displayLabel() {
    return this.serviceDeliveryPointForm.get('displayLabel');
  }

  get commodity() {
    return this.serviceDeliveryPointForm.get('commodity');
  }

  get descriptions() {
    return this.serviceDeliveryPointForm.get('descriptions');
  }
  get supportedLocales() {
    return this.serviceDeliveryPointForm.get('supportedLocales');
  }
  get status() {
    return this.serviceDeliveryPointForm.get('status');
  }

  get defaultLocale() {
    return this.serviceDeliveryPointForm.get('defaultLocale');
  }

  get startDttm() {
    return this.serviceDeliveryPointForm.get('startDttm');
  }

  get endDttm() {
    return this.serviceDeliveryPointForm.get('endDttm');
  }

  get sdpType() {
    return this.serviceDeliveryPointForm.get('sdpType');
  }

  get utilityMeterNumber() {
    return this.serviceDeliveryPointForm.get('utilityMeterNumber');
  }

  get loadingSites() {
    return this.siteService.loadingSites$;
  }

  get sites() {
    return this.siteService.sites$.pipe(
      tap(sites => {
        if (sites.length > 0) {
          const isSiteExist = sites.map(site => site.id).includes(this.siteId.value);
          if (!isSiteExist && sites.length <= 0) {
            this.siteId.setValue('');
          }
        }
      }),
    );
  }

  private _mode: string;
  private subscriptions: Subscription[] = [];
  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private typeService: TypeService,
    private commoditiesService: CommoditiesService,
    private siteService: SiteService,
    private serviceDeliveryPointService: ServiceDeliveryPointService,
    private translateService: TranslateService,
    private ngxCookieService: NgxCookieService,
    private orgSelectorService: ContextSelectorService,
    private sdpService: ServiceDeliveryPointService,
    private router: Router,
  ) {
    this.timezones = timezonesService.timezones;
    this.nameLabel = this.translateService.instant('utility.account.service_delivery_point_name');
    this.descriptionLabel = this.translateService.instant('utility.account.service_delivery_point_description');
    this.namePlaceholder = this.translateService.instant(
      'utility.account.create.placeholder.service_delivery_point_name',
    );
    this.descriptionPlaceholder = this.translateService.instant('utility.account.create.placeholder.description');
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
  }

  async ngOnInit() {
    if (this.isEditMode) {
      this.isFirstTime = false;
    }
    this.siteService.getSelectedSite().subscribe(selectedSite => {
      if (selectedSite && this.isFirstTime) {
        this.siteId.setValue(selectedSite);
        this.isFirstTime = false;
      }
    });
    this.loadDynamicLists();
    const typesSub = this.typeService.types$.subscribe(types => {
      if (types) {
        this.types = Object.keys(types).map(k => types[k]);
      }
    });

    const commoditiesSub = this.commoditiesService.commodities$.subscribe(commodities => {
      if (Array.isArray(commodities) && commodities.length > 0) {
        this.commodities = commodities;
      }
    });

    const localeServiceSub = this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
      let locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      if (this.isCreateMode) {
        this.multiLocaleConfig.defaultLocale = locale;
        this.defaultLocale.patchValue(locale.localeName);
        this.supportedLocales.patchValue([locale]);
      } else if (this.isEditMode) {
        this.multiLocaleConfig.defaultLocale = locale;
        this.buildSupportedLocales();
      }
    });

    if (this.isCreateMode) {
    } else if (this.isEditMode) {
      const sdpSub = this.serviceDeliveryPointService.serviceDeliveryPoint$.subscribe(async serviceDeliveryPoint => {
        if (serviceDeliveryPoint) {
          this.sdp = serviceDeliveryPoint;
          this.multiDescriptionDisplayLabels = serviceDeliveryPoint.descriptions;
          this.multidisplayLabels = serviceDeliveryPoint.displayLabels;
          this.buildSupportedLocales();
        }
      });
      this.orgSelectorService.currentContext$.subscribe(async orgs => {
        const shouldUpdateList = false;
        const forceSelectFirstSite = false;
        const org = orgs[0];
        if (this.sdp && org && this.siteId.value === '') {
          this.loadSite = true;
          const site = await this.getParent(org.id, this.sdp.id);
          if (site) {
            this.siteId.patchValue(site.id);
            this.serviceDeliveryPointService.setSdpParentId(site.id);
            this.siteService.setSelectedSite(site.id);
            this.siteService.selectSite(site.id);
            this.sdpService.getServiceDeliveryPoints(site.id, shouldUpdateList, forceSelectFirstSite);
          }

          this.loadSite = false;
        }
      });
      this.subscriptions.push(sdpSub);
    }
    this.subscriptions = [typesSub, commoditiesSub, localeServiceSub];
    this.triggerValidation();
  }

  isEdit() {
    return this.router.url.includes('edit');
  }

  ngAfterViewInit() {
    if (this.isCreateMode) {
      this.multiLocaleDisplayLabels.form.valueChanges.subscribe(multilocales => {
        const newValue = this.transformMultiLocaleValue(multilocales.localeInputsForm_sdp_display_labels);
        const status = this.multiLocaleDisplayLabels.form.status;
        this.updateDisplayLabels(status, newValue);
      });
    } else if (this.isEditMode) {
      this.multiLocaleDisplayLabels.form.valueChanges.subscribe(multilocales => {
        const status = this.multiLocaleDisplayLabels.form.status;
        if (status == 'INVALID') {
          this.displayLabels.setErrors({ required: true });
        } else {
          this.displayLabels.setErrors(null);
        }
      });
    }
    const multilocalesDescriptionSub = this.multiLocaleDescriptionDisplayLabels.form.valueChanges.subscribe(
      multilocales => {
        const newValue = this.transformMultiLocaleDescriptionValue(
          multilocales.localeInputsForm_sdp_description_display_labels,
        );
        const status = this.multiLocaleDescriptionDisplayLabels.form.status;
        this.updateDescriptionDisplayLabels(status, newValue);
      },
    );
    this.subscriptions.push(multilocalesDescriptionSub);
  }

  transformMultiLocaleValue(multilocales) {
    const displayLabels = {};
    if (multilocales) {
      const locales = Object.keys(multilocales);
      if (locales) {
        for (const locale of locales) {
          const key = locale
            .replace('sdp_display_labels_', '')
            .split('_')
            .map((item, i) => (i == 1 ? item.toUpperCase() : item))
            .join('_');
          displayLabels[key] = multilocales[locale];
        }
      }
    }
    return displayLabels;
  }

  transformMultiLocaleDescriptionValue(multilocales) {
    const descriptions = {};
    if (multilocales) {
      const locales = Object.keys(multilocales);
      if (locales) {
        for (const locale of locales) {
          const key = locale
            .replace('sdp_description_display_labels_', '')
            .split('_')
            .map((item, i) => (i == 1 ? item.toUpperCase() : item))
            .join('_');
          descriptions[key] = multilocales[locale];
        }
      }
    }
    return descriptions;
  }

  updateDisplayLabels(status, newValue) {
    this.displayLabels.setValue({ ...newValue });
    this.displayLabels.markAsTouched();
    if (status == 'INVALID') {
      this.displayLabels.setErrors({ required: true });
    } else {
      this.displayLabels.setErrors(null);
    }
  }

  updateDescriptionDisplayLabels(status, newValue) {
    this.descriptions.setValue({ ...newValue });
    this.descriptions.markAsTouched();
    if (status == 'INVALID') {
      this.descriptions.setErrors({ required: true });
    } else {
      this.descriptions.setErrors(null);
    }
  }

  async getParent(orgId, sdpId) {
    const shouldUpdateList = false;
    const forceSelectFirstSite = false;
    let allSites = await this.siteService.getSitesList().getValue();
    if (allSites.length === 0) {
      allSites = await this.siteService.getSites(orgId);
    }
    for (const site of allSites) {
      const sdpList = await this.serviceDeliveryPointService.getServiceDeliveryPoints(site.id, shouldUpdateList, forceSelectFirstSite);
      const parent = sdpList.filter(sdp => sdp.id === sdpId);
      if (parent.length > 0) {
        return site;
      }
    }
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return this.supportedLocales.value.length > num - 1 && !this.supportedLocales.value.includes(locale);
  }

  onlyLocaleSelected(locale) {
    return this.supportedLocales.value.length == 1 && this.supportedLocales.value[0] == locale;
  }
  handleSupportedLocalesSelection() {
    this.triggerValidation();
    const selectedLocales = this.supportedLocales.value.map(locale => locale.localeName);
    if (!selectedLocales.includes(this.defaultLocale.value)) {
      this.defaultLocale.setValue(selectedLocales[0]);
    }
  }
  handleSelectionChange() {
    setTimeout(() => {
      this.triggerValidation();
    }, 100);

    if (this.supportedLocales.value.length === 0) {
      this.supportedLocales.patchValue([...this.supportedLocales.value, this.multiLocaleConfig.defaultLocale]);
    }
  }

  buildSupportedLocales() {
    if (this.supportedLocales.value && this.displayLabels.value && this.locales) {
      this.supportedLocales.setValue([]);
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.displayLabels.value);
      for (let key of keys) {
        const localeFound = this.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.supportedLocales.patchValue([localeFound, ...this.supportedLocales.value]);
        }
      }
    }
  }

  triggerValidation() {
    this.displayLabels.markAsTouched();
    this.displayLabels.updateValueAndValidity();
  }

  getMinDate() {
    const minDate = new Date(this.startDttm.value);
    minDate.setDate(minDate.getDate() + 1);
    return minDate;
  }

  checkDates() {
    if (this.endDttm.value < this.startDttm.value) {
      return true;
    }
    return null;
  }

  private async loadDynamicLists() {
    this.commoditiesService.setCommodities();
    this.typeService.setTypes();
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
