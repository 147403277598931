import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class GlobalAlertService {
    private subject = new Subject();

    get messages(): Subject<any> {
        return this.subject;
    }

    setSuccess(message: string, timeout: number = 5000): void {
        this.messages.next({
            type: 'success',
            message,
            timeout
        });
    }

    setError(message: string, timeout: number = 5000): void {
        this.messages.next({
            type: 'error',
            message,
            timeout
        });
    }
}
